import React, { Component } from 'react';

import Ideal from '../assets/icons/payment/ideal.svg';
import Bancontact from '../assets/icons/payment/bancontact.svg';
import Pinnen from '../assets/icons/payment/pinnen.svg';
import Cash from '../assets/icons/payment/cash.svg';
import Sofort from '../assets/icons/payment/sofort.svg';
import Cartebancaire from '../assets/icons/payment/cartebancaire.svg';
import Festivalgiftcard from '../assets/icons/payment/festivalgiftcard.svg';
import Paypal from '../assets/icons/payment/paypal.svg';
import Mastercard from '../assets/icons/payment/mastercard.svg';
import Applepay from '../assets/icons/payment/applepay.svg';
import Sepa from '../assets/icons/payment/sepa.svg';

import INGBNL2A from '../assets/icons/payment/banks/INGBNL2A.svg';
import SNSBNL2A from '../assets/icons/payment/banks/SNSBNL2A.svg';
import ABNANL2A from '../assets/icons/payment/banks/ABNANL2A.svg';
import RABONL2U from '../assets/icons/payment/banks/RABONL2U.svg';
import ASNBNL21 from '../assets/icons/payment/banks/ASNBNL21.svg';
import KNABNL2H from '../assets/icons/payment/banks/KNABNL2H.svg';
import BUNQNL2A from '../assets/icons/payment/banks/BUNQNL2A.svg';
import REVOLUT from '../assets/icons/payment/banks/REVOLUT.svg';
import RBRBNL21 from '../assets/icons/payment/banks/RBRBNL21.svg';
import TRIONL2U from '../assets/icons/payment/banks/TRIONL2U.svg';
import FVLBNL22 from '../assets/icons/payment/banks/FVLBNL22.svg';

export default class extends React.Component  {

    width = this.props.width ? this.props.width : 50;
    height = this.props.height ? this.props.height : 50;

    render() {
        return (
            <div>
                {this.props.name === 'local:ideal' ? <img src={Ideal} width={this.width} height={this.height} /> :null}
                {this.props.name === 'local:bancontact' ? <img src={Bancontact} width={this.width} height={this.height} /> :null}
                {this.props.name === 'local:pinnen' ? <img src={Pinnen} width={this.width} height={this.height} /> :null}
                {this.props.name === 'local:cash' ? <img src={Cash} width={this.width} height={this.height} /> :null}
                {this.props.name === 'local:sofort' ? <img src={Sofort} width={this.width} height={this.height} /> :null}
                {this.props.name === 'local:cartebancaire' ? <img src={Cartebancaire} width={this.width} height={this.height} /> :null}
                {this.props.name === 'local:festivalgiftcard' ? <img src={Festivalgiftcard} width={this.width} height={this.height} /> :null}
                {this.props.name === 'local:paypal' ? <img src={Paypal} width={this.width} height={this.height} /> :null}
                {this.props.name === 'local:mastercard' ? <img src={Mastercard} width={this.width} height={this.height} /> :null}
                {this.props.name === 'local:applepay' ? <img src={Applepay} width={this.width} height={this.height} /> :null}
                {this.props.name === 'local:sepa' ? <img src={Sepa} width={this.width} height={this.height} />:null}

                {this.props.name === 'local:INGBNL2A' ? <img src={INGBNL2A} width={55} height={55} /> :null}
                {this.props.name === 'local:SNSBNL2A' ? <img src={SNSBNL2A} width={55} height={55} /> :null}
                {this.props.name === 'local:ABNANL2A' ? <img src={ABNANL2A} width={55} height={55} /> :null}
                {this.props.name === 'local:RABONL2U' ? <img src={RABONL2U} width={55} height={55} /> :null}
                {this.props.name === 'local:ASNBNL21' ? <img src={ASNBNL21} width={55} height={55} /> :null}
                {this.props.name === 'local:KNABNL2H' ? <img src={KNABNL2H} width={55} height={55} /> :null}
                {this.props.name === 'local:BUNQNL2A' ? <img src={BUNQNL2A} width={55} height={55} /> :null}
                {this.props.name === 'local:REVOLUT' ? <img src={REVOLUT} width={55} height={55} /> :null}
                {this.props.name === 'local:RBRBNL21' ? <img src={RBRBNL21} width={55} height={55} /> :null}
                {this.props.name === 'local:TRIONL2U' ? <img src={TRIONL2U} width={55} height={55} /> :null}
                {this.props.name === 'local:FVLBNL22' ? <img src={FVLBNL22} width={55} height={55} /> :null}

                {!this.props.name.includes('local:') ? <img src={this.props.name} width={this.width} height={this.height} /> :null}
            </div>
    )
    }
}